<template>
  <div>
    <vx-card class="mb-4">
      <div class="grid md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 flex gap-2 mb-5 " v-if="isEdit">
        <div class="">
          <vs-input
            id="protocol_number"
            class="w-full"
            :label="$t('appeal.protocol')"
            v-model="model.protocol_number"
            :readonly="true"/>
        </div>
        <div class="">
          <vs-input
            id="created_at"
            class="w-full"
            :label="$t('appeal.created_at')"
            v-model="model.created_dt_fmt"
            :readonly="true"/>
        </div>
        <div class="">
          <vs-input
            id="status"
            :label="$t('appeal.status')"
            class="text-warning w-full"
            v-model="model.handling_status.label"
            :readonly="true"/>
        </div>
        <div class="content-end">
          <div>
            <form-handling-history-popup :appeal_id="model.id"
                :visible.sync="popupHistoryView"
                @close="showHanlingHistoryPopup"></form-handling-history-popup>
            <vs-button
              class="float-base"
              color="primary"
              type="border"
              @click="popupHistoryView = true">{{ $t('appeal.handling.history') }}</vs-button>
            </div>
        </div>
      </div>
      <div class="w-full" v-if="isEdit">
        <vs-input
            id="model_name"
            class="w-full"
            :label="$t('Questionnaire')"
            v-model="model.model_name"
            :readonly="true"/>
      </div>
      <div class="w-full" v-else>
        <select-suggestion
        :max="20"
        :class="questionnaire ? 'flex-grow' : 'flex-grow required'"
        column="name,description"
        model="StudentContentQuestionnaire"
        :label="$t('Questionnaire')"
        v-model="questionnaire"
        :appendClearOption="true"
        ref="select_questionnaire"
        placeholderText="Digite uma avaliação..."/>
        <span
          class="text-danger text-sm"
          v-show="errors.has('questionnaire_id')">{{ errors.first('questionnaire_id') }}</span>
      </div>
      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <label class="text-sm pl-1">{{ $t('appeal.reason') }}</label>
          <complex-editor v-bind:editor_data.sync="model.reason"/>
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{errors.first('reason')}}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="text-sm pl-1">{{ $t('appeal.reasoning') }}</label>
          <complex-editor v-bind:editor_data.sync="model.reasoning"/>
          <span class="text-danger text-sm" v-show="errors.has('reasoning')">{{errors.first('reasoning')}}</span>
        </div>
      </div>
      <div class="w-full flex gap-2 justify-end">
        <vs-button
          v-if="!isEdit"
          v-permission="'appeals.create'"
          :disabled="!validateForm"
          class="float-right mr-2"
          @click="createOrUpdate">{{ $t('action.save') }}</vs-button>
          <vs-button
            v-if="isEdit"
            v-permission="'appeals.edit'"
            :disabled="!validateForm"
            class="float-right mr-2"
            @click="createOrUpdate">{{ $t('action.save') }}</vs-button>
        <vs-button
          class="float-right"
          color="primary"
          type="border"
          @click="goback">{{ $t('action.back') }}</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import ComplexEditor from '../questionnaires/elements/ComplexEditor.vue'
import FormHandlingHistoryPopup from './handling/FormHandlingHistoryPopup.vue'

import AppealService from '@/services/api/AppealService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    ComplexEditor,
    FormHandlingHistoryPopup
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    questionnaire: null,
    popupHistoryView: false,
    model: {
      id: null,
      reason : '',
      reasoning: '',
      protocol_number: '',
      model_id: null,
      model_type: null,
      model_name: null,
    }
  }),
  computed: {
    isEdit(){
      return !_.isNil(this.id)
    },
    validateForm() {
      if(this.isEdit){
        return !this.isEmpty(this.model.reason)
      }else{
        return !this.isEmpty(this.model.reason)
              && this.questionnaire != null
              && !this.isEmpty(this.questionnaire.id)
      }
    },

  },
  beforeMount() {
    this.service = AppealService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
        },
        error => {this.$vs.loading.close()}
      ).finally(() => {this.$vs.loading.close()})
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
        id: this.model.id,
        reason: this.model.reason,
        reasoning: this.model.reasoning,
      }

      if(!this.isEdit){
        modelData.model_id = this.questionnaire.id
      }

      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          if(!this.isEdit){
            this.$router.push(`/appeals/${data.id}/edit`)
          }
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(this.$vs,this.$t('nao-foi-possivel-realizar-esta-operacao'))
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    goback() {
      this.$router.push('/appeals')
    },
    isAdmin() {
      return isAdmin()
    },
    showHanlingHistoryPopup(show){
      this.popupHistoryView = show
    }
  }
}
</script>

<style>

</style>
