var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _vm.isEdit
          ? _c(
              "div",
              {
                staticClass:
                  "grid md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 flex gap-2 mb-5 ",
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        id: "protocol_number",
                        label: _vm.$t("appeal.protocol"),
                        readonly: true,
                      },
                      model: {
                        value: _vm.model.protocol_number,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "protocol_number", $$v)
                        },
                        expression: "model.protocol_number",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {},
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        id: "created_at",
                        label: _vm.$t("appeal.created_at"),
                        readonly: true,
                      },
                      model: {
                        value: _vm.model.created_dt_fmt,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "created_dt_fmt", $$v)
                        },
                        expression: "model.created_dt_fmt",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {},
                  [
                    _c("vs-input", {
                      staticClass: "text-warning w-full",
                      attrs: {
                        id: "status",
                        label: _vm.$t("appeal.status"),
                        readonly: true,
                      },
                      model: {
                        value: _vm.model.handling_status.label,
                        callback: function ($$v) {
                          _vm.$set(_vm.model.handling_status, "label", $$v)
                        },
                        expression: "model.handling_status.label",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "content-end" }, [
                  _c(
                    "div",
                    [
                      _c("form-handling-history-popup", {
                        attrs: {
                          appeal_id: _vm.model.id,
                          visible: _vm.popupHistoryView,
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.popupHistoryView = $event
                          },
                          close: _vm.showHanlingHistoryPopup,
                        },
                      }),
                      _c(
                        "vs-button",
                        {
                          staticClass: "float-base",
                          attrs: { color: "primary", type: "border" },
                          on: {
                            click: function ($event) {
                              _vm.popupHistoryView = true
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("appeal.handling.history")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm.isEdit
          ? _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    id: "model_name",
                    label: _vm.$t("Questionnaire"),
                    readonly: true,
                  },
                  model: {
                    value: _vm.model.model_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "model_name", $$v)
                    },
                    expression: "model.model_name",
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("select-suggestion", {
                  ref: "select_questionnaire",
                  class: _vm.questionnaire ? "flex-grow" : "flex-grow required",
                  attrs: {
                    max: 20,
                    column: "name,description",
                    model: "StudentContentQuestionnaire",
                    label: _vm.$t("Questionnaire"),
                    appendClearOption: true,
                    placeholderText: "Digite uma avaliação...",
                  },
                  model: {
                    value: _vm.questionnaire,
                    callback: function ($$v) {
                      _vm.questionnaire = $$v
                    },
                    expression: "questionnaire",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("questionnaire_id"),
                        expression: "errors.has('questionnaire_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("questionnaire_id")))]
                ),
              ],
              1
            ),
        _c("div", { staticClass: "vx-row mb-6 mt-6" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", { staticClass: "text-sm pl-1" }, [
                _vm._v(_vm._s(_vm.$t("appeal.reason"))),
              ]),
              _c("complex-editor", {
                attrs: { editor_data: _vm.model.reason },
                on: {
                  "update:editor_data": function ($event) {
                    return _vm.$set(_vm.model, "reason", $event)
                  },
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("reason"),
                      expression: "errors.has('reason')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("reason")))]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row mb-6" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", { staticClass: "text-sm pl-1" }, [
                _vm._v(_vm._s(_vm.$t("appeal.reasoning"))),
              ]),
              _c("complex-editor", {
                attrs: { editor_data: _vm.model.reasoning },
                on: {
                  "update:editor_data": function ($event) {
                    return _vm.$set(_vm.model, "reasoning", $event)
                  },
                },
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("reasoning"),
                      expression: "errors.has('reasoning')",
                    },
                  ],
                  staticClass: "text-danger text-sm",
                },
                [_vm._v(_vm._s(_vm.errors.first("reasoning")))]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "w-full flex gap-2 justify-end" },
          [
            !_vm.isEdit
              ? _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "appeals.create",
                        expression: "'appeals.create'",
                      },
                    ],
                    staticClass: "float-right mr-2",
                    attrs: { disabled: !_vm.validateForm },
                    on: { click: _vm.createOrUpdate },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.save")))]
                )
              : _vm._e(),
            _vm.isEdit
              ? _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "appeals.edit",
                        expression: "'appeals.edit'",
                      },
                    ],
                    staticClass: "float-right mr-2",
                    attrs: { disabled: !_vm.validateForm },
                    on: { click: _vm.createOrUpdate },
                  },
                  [_vm._v(_vm._s(_vm.$t("action.save")))]
                )
              : _vm._e(),
            _c(
              "vs-button",
              {
                staticClass: "float-right",
                attrs: { color: "primary", type: "border" },
                on: { click: _vm.goback },
              },
              [_vm._v(_vm._s(_vm.$t("action.back")))]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }